import currentEnv from '../../../src/core/utils/currentEnv.js'

const HOST_NAME = currentEnv.isProduction ? 'https://apiimg.iberostar.com' : 'https://pre-apiimg.iberostar.com'
let BASE_PATH = '/uploads/gmapz/'
// This timestamp is to use it as parameter in the pins URL's and force the browser to load new assets
const GMAPZ_PINS_TS = '202401050900'

if (IB.is_in_microsite && IB.current_microsite === 'ibercuba') {
  BASE_PATH = `/uploads/gmapz/${IB.current_microsite}/`
}

GMapz.APIKEY = 'AIzaSyB2w3P_pk1J15KgRmcs5Vt_H6gfczdXmC0'
;(GMapz.default_POI_image =
  'https://assets4.cdn.iberostar.com/assets/empty_state_POI-3e4830d14b7435ba5769a96267d59d583dde5e536b27011a8c0af0f07703f9a6.png'),
(GMapz.default_POI_image_mobile =
    'https://assets4.cdn.iberostar.com/assets/empty_state_POI_mobile-718a37bd415c833f1be11dce54da2b52427b9939ad925632fb7bdf7806017a84.png'),
(GMapz.pins = {
  default: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin.png`,
      size: [48, 48],
      anchor: [24, 48],
    },
  },
  cluster: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-cluster.png`,
      size: [44, 44],
      anchor: [36, 36],
    },
  },
  cluster_with_border: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-empty-cluster-with-border.png?ts=${GMAPZ_PINS_TS}`,
      size: [50, 50],
      anchor: [36, 36],
    },
  },
  cluster_with_border_green: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-cluster-with-border-green.png?ts=${GMAPZ_PINS_TS}`,
      size: [50, 50],
      anchor: [36, 36],
    },
  },
  hotel_star: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-hotel-star.png`,
      size: [72, 72],
      anchor: [36, 36],
    },
  },
  hotel_star_mini: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-hotel-star-mini.png`,
      size: [44, 44],
      anchor: [36, 36],
    },
  },
  hotel_star_mini_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-hotel-star-mini-selected.png`,
      size: [62, 62],
      anchor: [30.6, 51.7],
    },
  },
  hotel_star_mini_blue: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-hotel-star-mini-blue.png`,
      size: [43, 43],
      anchor: [22, 43],
    },
  },
  hotel_star_mini_blue_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-hotel-star-mini-blue-selected.png`,
      size: [62, 62],
      anchor: [30.6, 62],
    },
  },
  hotel_one: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-one-hotel.png`,
      size: [28, 28],
      anchor: [14, 28],
    },
  },
  hotel_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-one-hotel-selected.png?ts=${GMAPZ_PINS_TS}`,
      size: [50, 50],
      anchor: [25, 23],
    },
  },
  hotel_not_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-one-hotel-not-selected.png`,
      size: [50, 50],
      anchor: [14, 28],
    },
  },
  hotel_one_fav: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-fav-one-hotel.png`,
      size: [32, 30],
      anchor: [16, 30],
    },
  },
  hotel_blue: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-hotel-blue.png`,
      size: [22, 22],
      anchor: [11, 11],
    },
  },
  user_location: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-user-location.png`,
      size: [48, 48],
      anchor: [24, 48],
    },
  },
  poi_cat_1: {
    pin: {
      img: 'https://assets4.cdn.iberostar.com/gmapz/pin-accomodation/pin.png',
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_1_selected: {
    pin: {
      img: 'https://assets4.cdn.iberostar.com/gmapz/pin-accomodation/pin-selected.png',
      size: [62, 62],
      anchor: [31, 52],
    },
  },
  poi_cat_1_ocean: {
    pin: {
      img: 'https://assets4.cdn.iberostar.com/gmapz/pin-accomodation/pin-ocean.png',
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_1_ocean_selected: {
    pin: {
      img: 'https://assets4.cdn.iberostar.com/gmapz/pin-accomodation/pin-ocean-selected.png',
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_1_city: {
    pin: {
      img: 'https://assets4.cdn.iberostar.com/gmapz/pin-accomodation/pin-city.png',
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_1_city_selected: {
    pin: {
      img: 'https://assets4.cdn.iberostar.com/gmapz/pin-accomodation/pin-city-selected.png',
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_1_heritage: {
    pin: {
      img: 'https://assets4.cdn.iberostar.com/gmapz/pin-accomodation/pin-heritage.png',
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_1_heritage_selected: {
    pin: {
      img: 'https://assets4.cdn.iberostar.com/gmapz/pin-accomodation/pin-heritage-selected.png',
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_2: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-dining/pin.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_2_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-dining/pin-selected.png`,
      size: [62, 62],
      anchor: [31, 52],
    },
  },
  poi_cat_2_ocean: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-dining/pin-ocean.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_2_ocean_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-dining/pin-ocean-selected.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_2_city: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-dining/pin-city.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_2_city_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-dining/pin-city-selected.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_2_heritage: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-dining/pin-heritage.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_2_heritage_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-dining/pin-heritage-selected.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_3: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-tourist-attraction/pin.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_3_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-tourist-attraction/pin-selected.png`,
      size: [62, 62],
      anchor: [31, 52],
    },
  },
  poi_cat_3_ocean: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-tourist-attraction/pin-ocean.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_3_ocean_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-tourist-attraction/pin-ocean-selected.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_3_city: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-tourist-attraction/pin-city.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_3_city_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-tourist-attraction/pin-city-selected.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_3_heritage: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-tourist-attraction/pin-heritage.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_3_heritage_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-tourist-attraction/pin-heritage-selected.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_4: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-shopping/pin.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_4_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-shopping/pin-selected.png`,
      size: [62, 62],
      anchor: [31, 52],
    },
  },
  poi_cat_4_ocean: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-shopping/pin-ocean.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_4_ocean_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-shopping/pin-ocean-selected.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_4_city: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-shopping/pin-city.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_4_city_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-shopping/pin-city-selected.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_4_heritage: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-shopping/pin-heritage.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_4_heritage_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-shopping/pin-heritage-selected.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_5: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-nightlife/pin.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_5_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-nightlife/pin-selected.png`,
      size: [62, 62],
      anchor: [31, 52],
    },
  },
  poi_cat_5_ocean: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-nightlife/pin-ocean.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_5_ocean_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-nightlife/pin-ocean-selected.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_5_city: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-nightlife/pin-city.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_5_city_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-nightlife/pin-city-selected.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_5_heritage: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-nightlife/pin-heritage.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_5_heritage_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-nightlife/pin-heritage-selected.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_6: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-information/pin.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_6_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-information/pin-selected.png`,
      size: [62, 62],
      anchor: [31, 52],
    },
  },
  poi_cat_6_ocean: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-information/pin-ocean.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_6_ocean_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-information/pin-ocean-selected.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_6_city: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-information/pin-city.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_6_city_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-information/pin-city-selected.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_6_heritage: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-information/pin-heritage.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  poi_cat_6_heritage_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-information/pin-heritage-selected.png`,
      size: [45, 45],
      anchor: [23, 45],
    },
  },
  hotel_star_mini_blue_mobile: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-hotel-star-mini-blue-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  hotel_star_mini_mobile: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-hotel-star-mini-mobile.png`,
      size: [23, 23],
      anchor: [12, 23],
    },
  },
  hotel_star_mini_blue_mobile_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-hotel-star-mini-blue-selected-mobile.png`,
      size: [50, 50],
      anchor: [25, 50],
    },
  },
  hotel_star_mini_mobile_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-hotel-star-mini-selected-mobile.png`,
      size: [33, 33],
      anchor: [17, 28],
    },
  },
  poi_cat_1_mobile: {
    pin: {
      img: 'https://assets4.cdn.iberostar.com/gmapz/pin-accomodation/pin-mobile.png',
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_1_mobile_selected: {
    pin: {
      img: 'https://assets4.cdn.iberostar.com/gmapz/pin-accomodation/pin-selected-mobile.png',
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_1_ocean_mobile: {
    pin: {
      img: 'https://assets4.cdn.iberostar.com/gmapz/pin-accomodation/pin-ocean-mobile.png',
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_1_ocean_mobile_selected: {
    pin: {
      img: 'https://assets4.cdn.iberostar.com/gmapz/pin-accomodation/pin-ocean-selected-mobile.png',
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_1_city_mobile: {
    pin: {
      img: 'https://assets4.cdn.iberostar.com/gmapz/pin-accomodation/pin-city-mobile.png',
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_1_city_mobile_selected: {
    pin: {
      img: 'https://assets4.cdn.iberostar.com/gmapz/pin-accomodation/pin-city-selected-mobile.png',
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_1_heritage_mobile: {
    pin: {
      img: 'https://assets4.cdn.iberostar.com/gmapz/pin-accomodation/pin-heritage-mobile.png',
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_1_heritage_mobile_selected: {
    pin: {
      img: 'https://assets4.cdn.iberostar.com/gmapz/pin-accomodation/pin-heritage-selected-mobile.png',
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_2_mobile: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-dining/pin-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_2_mobile_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-dining/pin-selected-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_2_ocean_mobile: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-dining/pin-ocean-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_2_ocean_mobile_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-dining/pin-ocean-selected-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_2_city_mobile: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-dining/pin-city-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_2_city_mobile_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-dining/pin-city-selected-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_2_heritage_mobile: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-dining/pin-heritage-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_2_heritage_mobile_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-dining/pin-heritage-selected-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_3_mobile: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-tourist-attraction/pin-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_3_mobile_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-tourist-attraction/pin-selected-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_3_ocean_mobile: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-tourist-attraction/pin-ocean-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_3_ocean_mobile_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-tourist-attraction/pin-ocean-selected-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_3_city_mobile: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-tourist-attraction/pin-city-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_3_city_mobile_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-tourist-attraction/pin-city-selected-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_3_heritage_mobile: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-tourist-attraction/pin-heritage-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_3_heritage_mobile_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-tourist-attraction/pin-heritage-selected-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_4_mobile: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-shopping/pin-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_4_mobile_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-shopping/pin-selected-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_4_ocean_mobile: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-shopping/pin-ocean-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_4_ocean_mobile_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-shopping/pin-ocean-selected-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_4_city_mobile: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-shopping/pin-city-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_4_city_mobile_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-shopping/pin-city-selected-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_4_heritage_mobile: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-shopping/pin-heritage-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_4_heritage_mobile_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-shopping/pin-heritage-selected-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_5_mobile: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-nightlife/pin-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_5_mobile_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-nightlife/pin-selected-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_5_ocean_mobile: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-nightlife/pin-ocean-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_5_ocean_mobile_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-nightlife/pin-ocean-selected-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_5_city_mobile: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-nightlife/pin-city-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_5_city_mobile_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-nightlife/pin-city-selected-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_5_heritage_mobile: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-nightlife/pin-heritage-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_5_heritage_mobile_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-nightlife/pin-heritage-selected-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_6_mobile: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-information/pin-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_6_mobile_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-information/pin-selected-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_6_ocean_mobile: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-information/pin-ocean-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_6_ocean_mobile_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-information/pin-ocean-selected-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_6_city_mobile: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-information/pin-city-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_6_city_mobile_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-information/pin-city-selected-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_6_heritage_mobile: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-information/pin-heritage-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  poi_cat_6_heritage_mobile_selected: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}pin-information/pin-heritage-selected-mobile.png`,
      size: [36, 36],
      anchor: [18, 36],
    },
  },
  live_chat_active: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}live-chat-active.png`,
      size: [48, 48],
      anchor: [24, 48],
    },
  },
  live_chat: {
    pin: {
      img: `${HOST_NAME + BASE_PATH}live-chat.png`,
      size: [38, 38],
      anchor: [19, 38],
    },
  },
})
